<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Change Password</h4>
          </CCardHeader>
          <CCardBody>
          <b-form>

            <CRow>
                <CCol col="12" class="text-left">
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
            </CRow>

  		      <b-form-group id="input-group-1" label="Username:" label-for="input-1">
  		      <b-form-input v-model="userName" :disabled="!isDisabled" ></b-form-input>
  		      </b-form-group>

  		      <b-form-group id="input-group-2" label="New Password:" label-for="input-2">
  		        <b-form-input v-model="newPasswd" type="password" placeholder="Enter New Password"  required></b-form-input>
  		      </b-form-group>
						<transition name="hint" appear>
							<div v-if='passwordValidation.errors.length > 0 && !submitted' class='hints'>
								<h2>Hints</h2>
								<p v-for='error in passwordValidation.errors' style="margin-bottom:0px">{{error}}</p>
								<br />
							</div>
						</transition>
						<b-button type="button" @click="changePasswd" :disabled="submitable" variant="primary">Change Password</b-button>
		      </b-form>

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';

  export default {
    data() {
      return {
          isDisabled:false,
          userName: this.$route.params.name,
					rules: [
            { message:'One lowercase letter required.', regex:/[a-z]+/ },
            { message:"One uppercase letter required.",  regex:/[A-Z]+/ },
            { message:"15 characters minimum.", regex:/.{15,}/ },
            { message:"Symbol required.", regex:/[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/ },
            { message:"One number required.", regex:/[0-9]+/ }
          ],
          newPasswd: '',
          seen:false,
					submitable: false,
					submitted: false,
          msg :'',
          color:''
        } ;

    },
    methods: {
      async changePasswd() {

        if (!this.newPasswd ) {
            this.msg = "New Password Required";
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
        } else {

            try {
                  const parameters = { userName: this.userName, newPassword: this.newPasswd };
                  const resp = await AuthService.replacePasswdv2(parameters);
                  if(resp.status=="OK"){
                      this.msg   = "Replace Password Success!";
                      this.color = 'success'; this.seen  = true;
                  } else {
                      this.msg   =  !resp.data.message ? "Replace Password Failed!" : resp.data.message;
                      this.color = 'danger'; this.seen  = true;
                  }

                  setTimeout( () =>  this.seen=false , 5000);

              } catch (error) {
                console.log(error);
              }
            }
        }

    },
		computed: {
			passwordValidation () {
				let errors = []
				if(this.newPasswd !== ''){
					for (let condition of this.rules) {
						if (!condition.regex.test(this.newPasswd)) {
							errors.push(condition.message)
						}
					}
				}
				if (errors.length === 0) {
					this.submitable = (this.oldPasswd !== '' && this.newPasswd !== '' && this.oldPasswd !== this.newPasswd) ? false : true;
					return { valid:true, errors }
				} else {
					this.submitable = true;
					return { valid:false, errors }
				}
			}
		}
  }
</script>
